import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll() {
		const params = {
			order: 'asc',
			order_by: 'created_at',
			offset: 0,
			limit: 15,
		}
		const { data } = await useJwt.get(API_ROUTES.orderSchedule.get, params)

		return data
	},
}
